(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/poy-ranking-list/assets/javascripts/poy-ranking-list.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/poy-ranking-list/assets/javascripts/poy-ranking-list.js');
"use strict";

const {
  useState
} = React;
const {
  PokerPaper,
  PoyTableScrollable
} = svs.poker_react.components;
const {
  Inputv2,
  ControlGroup,
  Select,
  Option,
  Fieldset
} = svs.ui.reactForm;
const {
  ReactIconButton: IconButton,
  ReactIcon: Icon
} = svs.ui;
const {
  header,
  paragraph
} = svs.poker_react.data.poyCurrentRankingConfig;
const {
  fetchRankings,
  normalizePlayers,
  Periods,
  Tags
} = svs.poker_react.components.poyRankingList.common;
const PoyRankingList = () => {
  const [tag, setTag] = useState(Tags[0].value);
  const [period, setPeriod] = useState(Periods[0].value);
  const [searchString, setSearchString] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [isNameSearch, setIsNameSearch] = useState(false);
  const [playerList, setPlayerList] = useState(normalizePlayers(svs.poker_react.data.rankingData.rankingList));
  const [numberOfPlayers, setNumberOfPlayers] = useState(svs.poker_react.data.rankingData.numberOfPlayers);
  const [lastUpdated, setLastUpdated] = useState(svs.poker_react.data.rankingData.lastUpdated);
  const fetchData = async _ref => {
    let {
      period,
      searchString,
      tag
    } = _ref;
    const response = await fetchRankings({
      period,
      searchString,
      tag
    });
    setLastUpdated(response.lastUpdated);
    setPlayerList(response.players);
    setNumberOfPlayers(response.totalPlayerCount);
    setIsNameSearch(response.isNameSearch);
    setLastSearch(searchString);
  };
  return React.createElement("div", {
    className: "f-content poy-ranking-list-container"
  }, React.createElement("h2", null, header ? header : 'Player of the Year-rankningen'), React.createElement("p", null, paragraph), React.createElement(PokerPaper, null, React.createElement(Fieldset, {
    className: "grid-row gutter-xs-1 poker-poy-form-controls"
  }, React.createElement(ControlGroup, {
    className: "col-md-6",
    v2: true
  }, React.createElement(Select, {
    id: "tag",
    inverted: true,
    label: "Rankinglista",
    onChange: e => {
      setSearchString('');
      setTag(e.target.value);
      fetchData({
        period,
        searchString,
        tag: e.target.value
      });
    },
    v2: true,
    value: tag
  }, Tags.map(_ref2 => {
    let {
      label,
      value
    } = _ref2;
    return React.createElement(Option, {
      key: value,
      value: value
    }, label);
  }))), React.createElement(ControlGroup, {
    className: "col-md-6",
    v2: true
  }, React.createElement(Select, {
    id: "period",
    inverted: true,
    label: "Period",
    onChange: e => {
      setSearchString('');
      setPeriod(e.target.value);
      fetchData({
        period: e.target.value,
        searchString,
        tag
      });
    },
    v2: true,
    value: period
  }, Periods.map(_ref3 => {
    let {
      label,
      value
    } = _ref3;
    return React.createElement(Option, {
      key: value,
      value: value
    }, label);
  }))), React.createElement(ControlGroup, {
    className: "col-md-6 poy-search-input",
    v2: true
  }, React.createElement(Inputv2, {
    id: "qs",
    inputHelpText: "S\xF6k p\xE5 ett pokeralias",
    inverted: true,
    label: "S\xF6k",
    onChange: e => {
      if (e.target.value === '' && isNameSearch) {
        fetchData({
          period,
          searchString: e.target.value,
          tag
        });
      }
      setSearchString(e.target.value);
    },
    onKeyUp: e => {
      if (e.keyCode === 13 && searchString.length) {
        fetchData({
          period,
          searchString: e.target.value,
          tag
        });
      }
    },
    type: "text",
    value: searchString
  }), React.createElement(IconButton, {
    className: "poy-search-btn",
    onClick: () => {
      if (searchString.length) {
        fetchData({
          period,
          searchString,
          tag
        });
      }
    }
  }, React.createElement(Icon, {
    color: "fc-white",
    icon: "search",
    size: "200"
  })))), React.createElement(PoyTableScrollable, {
    isNameSearch: isNameSearch,
    numberOfPlayers: numberOfPlayers,
    playerList: playerList,
    searchString: lastSearch
  }), lastUpdated && React.createElement("span", null, "Uppdaterad: ".concat(lastUpdated))));
};
setGlobal('svs.poker_react.components.poyRankingList.PoyRankingList', PoyRankingList);

 })(window);